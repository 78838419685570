import { createAction, props } from '@ngrx/store';
import {User} from '../../interfaces/user.interface';

export enum UserActionsTypes {
    getUser = '[User] Get User',
    successGetUser = '[User] Success get User',
    errorGetUser = '[User] Error Get User',

    createUser = '[User] Create User',
    successCreateUser = '[User] Success Create User',
    errorCreateUser = '[User] Error Create User',

    updateUser = '[User] Update User',
    successUpdateUser = '[User] Success Update User',
    errorUpdateUser = '[User] Error Get User'
}

export const GetUser= createAction(UserActionsTypes.getUser, props<{ filters: string }>());
export const successGetUser = createAction(UserActionsTypes.successGetUser, props<{ user: Array<User> }>());
export const errorGetUser = createAction(UserActionsTypes.errorGetUser, props<{ payload: any }>());

export const createUser = createAction(UserActionsTypes.createUser, props<{ user: User }>());
export const successCreateUser = createAction(UserActionsTypes.successCreateUser, props<{ user: User }>());
export const errorCreateUser = createAction(UserActionsTypes.errorCreateUser, props<{ payload: any }>());

export const updateUser = createAction(UserActionsTypes.updateUser, props<{ user: User, id: string }>());
export const successUpdateUser = createAction(UserActionsTypes.successUpdateUser, props<{ user: User }>());
export const errorUpdateUser = createAction(UserActionsTypes.errorUpdateUser, props<{ payload: any }>());
